import {useState, useEffect} from "react";

import Scene from "./Scene";
import "./App.css";

const ThreeDApp = ({annotateConfigIndex}) => {
    const [annotateConfig, setAnnotateConfig] = useState(null);  

    useEffect(() => {
        if(annotateConfig == null) {
          const handleMessage = (event) => {
            // Use the configuration data received from the static website
            if(Array.isArray(event.data) && event.data[annotateConfigIndex] && event.data[annotateConfigIndex].annotateChips) {
              setAnnotateConfig(event.data[annotateConfigIndex].annotateConfig);
            }
          };
          
          window.addEventListener("message", (event)=> {handleMessage(event)});
        
          return () => {
            window.removeEventListener("message", handleMessage);
          };
        }
    }, [!annotateConfig]);

    return (
        <Scene annotateConfig={annotateConfig} />
    );
};

export default ThreeDApp;
