import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ThreeDApp from './ThreeDApp';
import reportWebVitals from './reportWebVitals';

/* const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
); */

/* Root container for AiDE Chat DIY */
if(document.getElementById('default-3D-container')) {
  const root = ReactDOM.createRoot(document.getElementById('default-3D-container'));
  root.render(
    <ThreeDApp annotateConfigIndex={0}/>
  );
}

if(document.getElementById('usecase-3D-container')) {
  const root = ReactDOM.createRoot(document.getElementById('usecase-3D-container'));
  root.render(
    <ThreeDApp annotateConfigIndex={1}/>
  );
}

/* Root container for AiDE Chat */
if(document.getElementById('root')) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <App />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
