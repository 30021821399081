import Scene from "./Scene";
import "./App.css";
import { useState } from "react";

const dummyAnnotateConfig = [
  {
    id:"default-24",
    nodeId:24,
    nodeName:"Sphere_023",
    title:"Train Teams on Codebase to Boost Day One Productivity",
    description: "Train Teams on Codebase to Boost Day One Productivity",
    videoUrl: "https://youtu.be/RCyHCA3xHw8"
  },
  {
    id:"default-8",
    nodeId:8,
    nodeName:"Sphere_012",
    title:"Brownfield",
    description: "Enhance my existing codebase",
    //videoUrl: "https://d1k5qim9574h9e.cloudfront.net/wp-content/uploads/2023/10/AiDE-video.mp4"
  },
  {
    id:"default-6",
    nodeId:6,
    nodeName:"Sphere_014",
    title:"Mordenization",
    description: "Enhance my existing codebase",
    videoUrl: "https://youtu.be/DGKG0aOWTW8"
  },
  {
    id:"default-2",
    nodeId:2,
    nodeName:"Sphere_04",
    title:"Code Documentation",
    description: "Enhance my existing codebase",
    videoUrl: "https://youtu.be/H8zRSbSucJI"
  },
  {
    id:"default-20",
    nodeId:20,
    nodeName:"Sphere_016",
    title:"Brownfield Use Case 4",
    description: "Enhance my existing codebase",
    videoUrl: "https://youtu.be/XbbS91jkVuw"
  },
  {
    id:"default-10",
    nodeId:10,
    nodeName:"Sphere_019",
    title:"Brownfield Use Case 5",
    description: "Enhance my existing codebase",
    videoUrl: "https://youtu.be/XbbS91jkVuw"
  }
];


const brownfieldAnnotateConfig = [
  {
    id:"brownfield-24",
    nodeId:24,
    nodeName:"Sphere_023",
    title:"Enhancing Existing Codebase",
    description: "Enhancing Existing Codebase",
    videoUrl: "https://d1k5qim9574h9e.cloudfront.net/wp-content/uploads/2023/10/AiDE-video.mp4"
  },
  {
    id:"brownfield-8",
    nodeId:8,
    nodeName:"Sphere_012",
    title:"Take New Features to Market Quickly",
    description: "Take New Features to Market Quickly",
    videoUrl: "https://d1k5qim9574h9e.cloudfront.net/wp-content/uploads/2023/10/AiDE-video.mp4"
  },
  {
    id:"brownfield-6",
    nodeId:6,
    nodeName:"Sphere_014",
    title:"Enable Engineering to Keep Pace with Product",
    description: "Enable Engineering to Keep Pace with Product",
    videoUrl: "https://d1k5qim9574h9e.cloudfront.net/wp-content/uploads/2023/10/AiDE-video.mp4"
  },
  {
    id:"brownfield-2",
    nodeId:2,
    nodeName:"Sphere_04",
    title:"Train Teams on Codebase to Boost Day One Productivity",
    description: "Train Teams on Codebase to Boost Day One Productivity",
    videoUrl: "https://d1k5qim9574h9e.cloudfront.net/wp-content/uploads/2023/10/AiDE-video.mp4"
  }
];


const App = () => {

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  return (<>
  <div style={{width: "100vw", height: "100vh"}}>
  <Scene annotateConfig={dummyAnnotateConfig} />
  </div>

    {/* <div style={{display: "flex", flexDirection: "row"}}>
      <button type="text" onClick={()=> {setShow1(!show1)}}>Show 1</button>
      <button type="text" onClick={()=> {setShow2(!show2)}}>Show 2</button>
    </div>
    <div style={{display: "flex", flexDirection: "row"}}>
      {show1 && <div style={{width: "50vw", height: "75vh", position:"relative", border:"3px solid red"}}>
        <Scene annotateConfig={dummyAnnotateConfig} />
      </div>}

      {show2 && <div style={{width: "50vw", height: "75vh", position:"relative", border:"3px solid blue"}}>
        <Scene annotateConfig={brownfieldAnnotateConfig} />
      </div>}
    </div> */}
    </>
  );
};

export default App;
